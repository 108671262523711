.list-view-table .list-view-icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: end;
}

.list-view-table .list-view-header {
    display: flex;
    align-items: center;
}
.list-view-table .list-view-cell {
    width: 1em;
}

.list-view-table .table-filter-box {
    position: relative;
}
.list-view-table .table-filter-buttonDiv {
    display: flex;
    align-items: center;
    position: relative;
}
.list-view-table .table-filter-inputDiv {
    position: absolute;
    z-index: 999;
    top: 100%;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    background-color: white;
}
.list-view-table .right-0 {
    right: 0px !important;
}

/* Right auto otherwise */
.list-view-table .right-auto {
    right: auto !important;
}

.list-view-table .table-filter-starDiv {
    color: red;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}